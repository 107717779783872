// Styles for Pager pagination
// -----------------------------------------------------------------------------

.pagination {
  .pager-ellipsis {
    > span {
      background: none;
      border: 0;
      color: $text-color;

      &:hover {
        color: $text-color;
        background: none;
      }
    }
  }
}