/**
* @file
* Styles for shs module on forms.
*/
@import "../bootswatch/_bootswatch.scss";

// Remove background from shs-element with children
// We use glyphicon instead
.chosen-container {
  .has-children {
    background: none;
    position: relative;

    &:after {
      display: block;
      right: 10px;
      position: absolute;
      top: 0;
      font-family: 'Glyphicons Halflings';
      content: "\e080";
      line-height: 35px;
      font-size: $font-size-small;
    }
  }
}
.shs-term-add-new-wrapper {
  margin: 0;
  white-space: nowrap;

  .buttons {
    display: inline-block;
    white-space: nowrap;
  }
  a {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-default;
    display: inline-block !important;
    width: auto !important;
  }
  a + a {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-primary;
    float:left;
    margin-right: 10px
  }
  input {
    @extend .form-control;
    width: 200px;
    margin-right: 10px;
    display: inline-block;
  }
}