@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("../fonts/glyphicons-halflings-regular.eot");
  src: url("../fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/glyphicons-halflings-regular.woff") format("woff"), url("../fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
}

.navbar-brand {
  background-image: url(../images/kp-logo.png);
  background-repeat: no-repeat;
  background-position: right center;
  text-indent: -9999em;
  width: 150px;
  height: 50px;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 10px;
}

.toggle {
  text-decoration: none;
  border-bottom: 1px dashed $link-color;
  &:hover, &:focus {
    text-decoration: none;
    border-bottom-color: $link-hover-color;
  }
}

.referenced-tasks {
  .views-field-field-status {
    width: 10%;
  }
  .views-field-field-ref-employee {
    width: 20%;
  }
  .views-field-field-task-deadline {
    width: 15%;
  }
}

.internal-documents--list {
  .task-comments--inline {
    .views-field-field-ref-employee {
      width: 8%;
    }
    .views-field-created {
      width: 7%;
    }
  }
}

div.date-display-remaining {
  text-transform: lowercase;
  font-size: $font-size-small;
  .internal-documents--list & {
    display: inline;
    &:before {
      content: " (";
    }
    &:after {
      content: ")";
    }
  }
}

.employee--full-name--brief {
  white-space: nowrap;
}

.radix-layouts-contentheader {
  .pane-entity-form-field {
    display: inline-block;
    vertical-align: top;
  }
}

.referenced-tasks--embed {
  .list-group-item {
    overflow: hidden;
    & > .views-field-field-ref-employee {
      .list-group {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        border-left: 1px solid #ddd;
        height: 0;
        margin: 0;
        li {
          width: 25%;
          border-left-width: 0;
          padding: 5px 10px;
        }
        small {
          display: block;
        }
      }
    }
    & > .views-field-view {
      // width: 70%;
    }
  }
  .view-header {
    h4 {
      margin: 0;
    }
  }
}

.hierarchical-select-wrapper {
  .hierarchical-select {
    // display:-webkit-flex;
    // display:-ms-flexbox;
    // display:flex;

    .add-to-dropbox {
      margin: 0 0 0 .4em;
      display:inline-block;
    }

    .selects {
    //   -webkit-flex-basis:50%;
    //       -ms-flex-preferred-size:50%;
    //           flex-basis:50%;
    //   -webkit-flex-grow:1;
    //       -ms-flex-positive:1;
    //           flex-grow:1;
      display:inline-block;

      .form-control {
        width:auto;
        display:inline-block;
      }
    }
    .create-new-item {
      display:-webkit-flex;
      display:-ms-flexbox;
      display:flex;
      width:100%;
      padding:0;
      margin:0;
      border:0;
    }

    select {
      height:34px;
      margin:0;
    }

    .create-new-item-input {
      display:-webkit-flex;
      display:-ms-flexbox;
      display:flex;
      -webkit-flex-grow:1;
          -ms-flex-positive:1;
              flex-grow:1;
      margin:0;
      -webkit-flex-basis:50%;
          -ms-flex-preferred-size:50%;
              flex-basis:50%;
    }

    .create-new-item-create,
    .create-new-item-cancel {
      margin-left: .4em;
    }

  }
}

///////////////////////////////////////////////////////////////////////////////////////
// Content type: task
// Context: Node add/edit form

#edit-field-ref-project,
#edit-field-ref-parent-task {
  .form-type-checkbox {
    display:flex;
  }
  label.option {
    white-space: normal;
    padding:0;
  }
  h5 {
    margin:0;
  }
}

///////////////////////////////////////////////////////////////////////////////////////
// Content type: btnd_certificates
// Context: Node edit form
// Media query: @screen-sm
@media (max-width: $screen-sm-max), (min-width: $screen-lg-min) {
  .field-name-field-cert-expiration-type {
    display: inline-block;
    vertical-align: sub;
  }

  .field-name-field-cert-expiration-date {
    display: inline-block;
    vertical-align: baseline;
    .form-type-date-combo {
      margin: 0 0 0 10px;
    }
  }
}

/////////////////////////////////////////////////////////////////////////////////////
//Module Masquerade
.form-item-masquerade-user-field {
  display: inline-block !important;
}
