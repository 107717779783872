// Temp styles
// MUST BE DESABLED ON PRODUCTION
// -----------------------------------------------------------------------------
// Fix Krumo 'orange' theme
.search-krumo {
  color:#666;
}
.krumo-root {
  -webkit-font-smoothing: initial !important;
  letter-spacing: initial !important;
}
a.krumo-name {
  color:#404000 !important;
  .krumo-element.krumo-query-result & {
    color: #fff !important;
  }
}
li.krumo-footnote {
  h6 {
    color:#E87400 !important;
  }
  a {
    color: #434343 !important;
    &:hover {
      color:black !important;
    }
  }
}
.krumo-child,
.krumo-call {
 color: black !important;
}

div.krumo-element {
  background: #fceba9;
}
div.krumo-expand {
  background: #fadb61;
}
div.krumo-hover {
    background: #ff8a4b !important;
}
div.krumo-preview {
  background: #ffffff !important;
}

div#themer-popup {
  display: flex !important;
  flex-direction: column;
}
div#themer-popup .attributes.row {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
div#themer-popup .themer-variables {
  flex-grow: 1;
  overflow: auto;
}
div#themer-popup .krumo-root {
  overflow: visible;
}