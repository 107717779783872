// Styles for overrides and fixes
// -----------------------------------------------------------------------------

// adminimal_menu.module
// Fix font smooting of adminimal admin theme
body.adminimal-menu div#admin-menu a,
body.adminimal-menu div#admin-menu span {
  -webkit-font-smoothing: initial;
  letter-spacing: initial;
}

// Pagination
.item-list ul.pagination {
  margin: 20px 0 0;
}

// Throbber
.ajax-progress .throbber {
  margin: 0;
}

// Bootstrap Datetime picker
.bootstrap-datetimepicker-widget table td.active {
  background: $link-color;

  &:hover {
    background-color: $link-hover-color;
  }
}

.timepicker-picker {
  tr:first-of-type td {
    border-top: 0;
  }

  .btn {
    box-shadow: none;
  }
}

// Image field
div.image-widget-data {
  float: none;
}
