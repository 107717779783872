// Styles for Forms
// -----------------------------------------------------------------------------
@import '../bootswatch/_bootswatch.scss';

// Forms
textarea,
textarea.form-control,
input.form-control,
input[type=text],
input[type=password],
input[type=email],
input[type=number],
[type=text].form-control,
[type=password].form-control,
[type=email].form-control,
[type=tel].form-control {
  border: 1px solid $gray-lighter !important;
  padding: .5em !important;

  &:active,
  &:focus {
    border: 1px solid $brand-primary !important;
  }
}

label,
form .field-multiple-table thead th label {
  color: $brand-primary;
  display: block;
  font-size: $font-size-base;
  font-weight: normal;
}

fieldset legend {
  background: none !important;
  border: 0 !important;
  padding: 0 !important;

}

.field-type-file input[type="file"] {
  line-height: 1;
}

.form-control {
  border-radius: 0;

  &.chosen-container {
    border: 0;
  }
}

.form-control::-webkit-input-placeholder {
  color: transparent;
  transition: 240ms;
}

.form-control:focus::-webkit-input-placeholder {
  transition: none;
}

.form-group.focused .form-control::-webkit-input-placeholder {
  color: #bbb;
}

.form-group .help-block {
  line-height: 1.2;
  margin: 5px 0 0;
}

// Chosen
.chosen-container-single {
  box-shadow: none;
  padding: 0;
  width: 100% !important;

  &.form-control {
    height: auto;
  }

  .chosen-single {
    background: none;
    border: 0;
    border-radius: 0;
    border-width: 0;
    box-shadow: inset 0 -1px 0 #ddd;
    font-size: 16px;
    height: auto;
    padding: 0;
    line-height: 1.846;

    span {
      line-height: 1;
      padding: 10px 0;
      white-space: normal;
    }

    div {
      display: none;
    }
  }

  .chosen-drop {
    border: 1px solid $brand-primary;
    border-radius: 0;
    box-shadow: inset 0 1px 0 #ddd, 0 4px 5px rgba(0, 0, 0, .15);
    font-size: 16px;
    margin-top: -2px;
    width: 290px;
  }

  .chosen-results {
    margin: 0;
    padding: 0;

    li.active-result {
      padding: 10px;
    }
  }
}

.chosen-container-active {
  @include box-shadow(inset 0 -2px 0 $brand-primary);
}

// Remove search box in lists of level-1 and level-4 on particular pages
.page-personnel #edit-shs-term-node-tid-depth-wrapper [class*="level-1"] .chosen-search,
.page-personnel #edit-shs-term-node-tid-depth-wrapper [class*="level-4"] .chosen-search,
.page-documents #edit-shs-term-node-tid-depth-wrapper [class*="level-1"] .chosen-search,
.page-documents #edit-shs-term-node-tid-depth-wrapper [class*="level-4"] .chosen-search,
.page-library #edit-field-company-unit-tid-wrapper [class*="level-1"] .chosen-search,
.page-library #edit-field-company-unit-tid-wrapper [class*="level-4"] .chosen-search,
.page-library #edit-field-company-units-tid-wrapper [class*="level-1"] .chosen-search,
.page-library #edit-field-company-units-tid-wrapper [class*="level-4"] .chosen-search,
.page-library #edit-shs-term-node-tid-depth-wrapper [class*="level-1"] .chosen-search,
.page-library #edit-shs-term-node-tid-depth-wrapper [class*="level-4"] .chosen-search,
.page-node-add #edit-field-emergency-type .chosen-search,
.page-node-edit #edit-field-emergency-type .chosen-search {
  display: none;
}

// Chosen
.chosen-container-active {
  &.chosen-with-drop .chosen-single {
    background: none;
    border: 0;
  }
}

// Node forms
.page-node-add,
.page-node-edit {
  .chosen-container {
    width: auto !important;
    margin-right: 22px;

    &[class*="level-1"] {
      margin-left: 0;
    }

    .chosen-single {
      span {
        margin: 0;
      }
    }
  }

  // .chosen-container:last-of-type:before,
  .chosen-container:last-of-type:after {
    display: none !important;
  }

  .chosen-container ~ .chosen-container:after,
  .chosen-container[class*="level-2"]:before {
    bottom: 3px;
    color: $gray-light;
    content: '\e080';
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-size: $font-size-small;
    line-height: 1;
    padding: 10px 0;
    position: absolute;
    right: -18px;
    vertical-align: middle;
  }

  .chosen-container[class*="level-2"]:before {
    left: -18px !important;
  }
}

// * shs: Add new
.shs-term-add-new-wrapper {}

// date fields
.date-form-element-content {
  margin-top: $line-height-computed/2;
  overflow: hidden;

  .date-float {
    float: none;
    margin-bottom: .5em;
  }

  > div {
    float: left;
    margin: 0 1em 0 0 !important;
    padding-top: 0;

    // Remove padding in last element
    &:last-child {
      margin-right: 0 !important;
    }
  }
}

// Multiple value fields
.draggable {
  .field-multiple-drag {
    vertical-align: middle !important;
    width: 31px;
  }

  a.tabledrag-handle {
    display: block;
    float: none;
    height: auto;
    margin: 4px 0 0;
    padding: 8px;
    text-align: center;

    .handle {
      background-position: center top;
      display: block;
      height: 15px;
      margin: 0 auto;
      padding: 0;
      width: 15px;
    }
  }
}

// Multivalue fields
.form-multiple-value {
  .tabledrag-changed {
    display: none;
  }

  .field-multiple-table {
    border: 0;

    tbody tr td {
      padding-top: 1em !important;
    }

    tr {
      background: none !important;
    }

    th,
    td {
      border: 0;
      padding: 0 !important;
      vertical-align: middle;
    }
  }
}

// Unsorted
input[type="radio"],
input[type="checkbox"] {
  margin: 7px 0 0;
}

// .checkboxes-radios-group--label
.checkbox-radio--label {
  color: $text-color;
  font-size: 16px;
  font-weight: normal;
}

.radio,
.checkbox {
  margin-bottom: 0;
}

.radio + .radio,
.checkbox + .checkbox {
  margin: 0;
}

.tabledrag-toggle-weight-wrapper {
  display: none;
}

.panel-default,
.form-group,
.form-wrapper {
  border: 1px solid $gray-light;

  &:hover {
    border: 1px solid $brand-primary;
  }
}

.panel,
.form-group,
.form-wrapper {
  border-radius: 0;
  margin: 0;
  padding: 1em;

  * + & {
    margin: $line-height-computed 0 0;
  }

  & & {
    border: 0;
    margin: 0;
    padding: 0;
  }

  &:first-child {
    margin: 0;
  }

  &:hover & {
    border: 0;
  }
}

.form-actions {
  border-width: 0;
  padding: 0;

  &:hover {
    border: 0;
  }
}

.field-name-field-serial-number label,
.field-name-field-asset-id label,
.field-name-field-photos legend,
.field-name-field-videos legend {
 display: none !important;
}

.form-wrapper {
  fieldset {
    box-shadow: none;

    legend {
      background: none !important;
      padding: 0;
    }
  }
}

// Entityreference form controls
.field-type-entityreference {
  label {
    display: block !important;
  }
}

.field-widget-inline-entity-form {
  .form-submit {
    margin-right: 10px;
  }
}

// File widget
.file-widget {
  input[type="submit"] {
    margin-left: 10px;
  }

  .messages {
    margin: 0 0 1em;

    &.error {
      color: $brand-danger;
    }
  }
}

.help-block {
  margin: 0;
}

// Overrides for module file_resup
.file-resup-wrapper {
  margin: 0;

  .drop {
    margin: 0;
    padding: 10px;
  }

  .drop-message {
    padding: $line-height-computed 10px;
  }

  .file-list + .drop-message {
    display: block;
    padding: $line-height-computed 10px $line-height-computed/2;
  }

  .item-list {
    ul {
      margin: 0;
      padding: 0;

      li {
        line-height: 1;
        list-style: none;
        margin: 0;

        + li {
          margin-top: $line-height-computed/2;
        }

        strong {
          font-weight: normal;
        }
      }
    }
  }

  .progress {
    margin: $line-height-computed 0;
  }

  .messages {
    margin: 0 0 $line-height-computed/2;

    &.error {
      color: $brand-danger;
    }
  }

  a.remove {
    color: $brand-danger;
    margin-right: 5px;
    padding-left: 14px;
    text-decoration: none !important;

    span {
      @extend .glyphicon;
      @extend .glyphicon-remove;
      text-indent: -9999em;
    }

    ::before {
      left: -14px;
      position: absolute;
      text-indent: 0;
    }
  }

  a.button {
    @extend .btn;
    @extend .btn-default;
    margin: $line-height-computed/2 10px $line-height-computed/2 0;
  }
}

// Hide second label of file field
fieldset .form-type-managed-file > label {
  display: none;
}

// Fix margin of legend in file widgets and in photos/videos field groups
.group-photos legend,
.group-videos legend,
.field-type-file fieldset legend {
  margin-bottom: $line-height-computed/4;
}
