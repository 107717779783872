// Styles for Views
// -----------------------------------------------------------------------------

.sliding-filters {

  .toggle-button + & {
    margin-top: 1em;
  }

  .chosen-container {
    display: block !important;
  }

  .chosen-container:before {
    display: none !important;
  }
}

.filters-container,
.content-container {
  @include transition(all 250ms ease-in-out);
}

@media (min-width: 768px) {
  .sliding-filters {
    display: flex; /* or inline-flex */
  }

  .filters-container,
  .content-container {
    align-self: flex-start;
  }

  .filters-container {
    flex: 0 0 300px;
    padding: 0 15px 0 0;
  }

  .content-container {
    flex: 0 0 100%;
  }

  .collapsed {
    .filters-container {
      margin-left: -315px;
      margin-right: 15px;
    }
  }
}

@media (max-width: 767px) {
  .filters-container {}
  .content-container {}
  .collpased .filters-container {
    display: none;
  }
}

// Views exposed form buttons
.views-submit-button,
.views-reset-button {
  display: inline-block;
}

.views-reset-button {
  margin-left: 15px;
}

// Modal ERVW
.view-display-id-ervw_projects,
.view-display-id-ervw_employees {
  .table > thead > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > th,
  .table > tbody > tr > td,
  .table > tfoot > tr > th,
  .table > tfoot > tr > td {
    vertical-align: middle;
  }
}

// ERVW view widget
.field-widget-entityreference-view-widget {
  .draggable {
    .field-suffix,
    label {
      display: inline-block !important;
    }
  }
}

// Embeded view: organizational_units > embed_company_regional_units
.view-organizational-units {

  table {
    @extend .table;
    @extend .table-hover;
    table-layout: fixed;
  }

  .views-field-field-postal-address,
  .views-field-field-legal-address {
    width: 30%;
  }

  .views-field-field-phones {
    width: 15%;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  .views-field-view {
    padding: 0;
  }
}

// Views attachment
#overlay-menu-panel {

  .view-id-intranet_help > .attachment-before + * {
    margin-top: $line-height-computed;
  }

  .view-id-intranet_help > * + .attachment-after {
    margin-top: $line-height-computed;
  }

  .view-display-id-pane_context_help > .view-empty {
    display: none;
  }

  .pane-intranet-help-pane-context-help > .pane-title {
    margin: 0;
  }
}

// View: company/branches
.title--branch {
  .view-display-id-pane_company_branches * + & {
    margin-top: $line-height-computed;
  }

  & > h4 {
    margin: 0;
  }
}

.title--regional-unit {
  .view-display-id-pane_company_branches & {
    margin-top: $line-height-computed/4;
  }
}

// View: company/branches/manage
.view-display-id-embed_company_regional_units_manage {

  table {
    margin: 0;
  }

  .views-field-title {
    padding-left: 2em;
  }

  table > tbody > tr:first-child > td {
    border-top-width: 0;
  }

  table > tbody > tr:last-child > td {
    border-bottom-width: 0;
  }
}

// Masonry
.masonry-item {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

// Equipment models
.view-equipment-models {
  ul {
    padding-left: $line-height-computed;
  }

  .views-tree-parent > div > a:first-child {
    font-weight: bold;
  }
}

// Inline actions
.btn-inline-actions {
  @include transition(opacity, 200ms, ease-in-out);
  background-color: $btn-primary-bg;
  border-radius: 0;
  box-shadow: none;
  color: $btn-primary-color;
  line-height: 1;
  margin-left: 5px;
  opacity: 0;
  padding: 0 3px;

  &:active,
  &:focus,
  h3:hover > &,
  li > div:hover > & {
    color: $btn-primary-color;
    opacity: 1;
  }
}

.inline-actions-menu {
  background-color: $body-bg;
  border: 1px solid $brand-primary;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .38) !important;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1000;

  > .nav {
    padding-left: 0;
  }
}
