// Styles for modal window
// -----------------------------------------------------------------------------

#modal-content {
  & .toggle-button {
    margin: $line-height-computed*.75 0 0 $line-height-computed*.75;
  }
  & .sliding-filters {
    padding: $line-height-computed*.75;
  }
}

// views.module
// Fix doubling of toggle-button in views
#modal-content > div .toggle-button {
  display:none;
}

// Modals
#modalBackdrop {
  background: rgba(0, 0, 0, .55) !important;
  opacity: 1 !important;
}

#modalContent {
  position: initial !important;
  top: initial !important;
  left: initial !important;
  display: block !important;

  width: initial !important;
  height: initial !important;
  overflow: auto !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  padding:30px;

  .modal-dialog {
    position: initial !important;
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
  }

  .modal-content {
    width: initial !important;
    height: initial !important;
    overflow: initial !important;

    position: fixed;
    background: #fff;
    top: 5%;
    left: 5%;
    right: 5%;
    bottom: 5%;
    z-index: 1002;
    border-radius: 0;
    border: 1px solid $brand-primary;
    box-shadow: 0 5px 25px rgba(0, 0, 0, .38) !important;
  }

  .modal-header {

    .modal-title {
      display: none;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 999;
      height: $font-size-h2;
      width: $font-size-h2;
    }

    .close {
      color: $brand-primary;
      opacity: 1;
      text-shadow: initial;
      text-indent: -99999em;
      outline: 0;
      margin: 0;

      &:after {
        display: block;
        content: "\e014";
        position: absolute;
        top: 0;
        right: 0;
        text-indent: 0;
        font-family: 'Glyphicons Halflings';
        font-size: $font-size-h2;
        font-style: normal;
        font-weight: normal;
        padding: 15px;
      }
    }
  }
}

#modal-content {
  width: initial !important;
  height: initial !important;
  overflow: auto !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  left: 0 !important;
  padding:0;
}

// Autodialog (jQuery UI)
.ui-widget-overlay {
  background:rgba(0, 0, 0, .55) !important;
  z-index: 1000 !important;
  position: fixed !important;
  left: 0 !important;
  margin: 0 !important;
  top: 0 !important;
  height: 100% !important;
  width: 100% !important;

  & + #modalBackdrop + #modalContent .modal-content {
    left: 7% !important;
    top: 7% !important;
    right: 7% !important;
    bottom: 7% !important;
  }
}
.ui-dialog {
  position: initial !important;
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  top: initial !important;
  left: initial !important;
  display: initial !important;
}
.ui-dialog-titlebar {
  top: 5% !important;
  position: absolute !important;
  left: 5% !important;
  width: initial !important;
  right: 5% !important;
  border:none !important;
}
.ui-dialog-titlebar-close {
  z-index: 1000 !important;
  color: $brand-primary !important;
  opacity: 1 !important;
  text-shadow: initial !important;
  text-indent: -99999em !important;
  outline: 0 !important;
  margin: 0 !important;
  background: none !important;
  border: none !important;
  top: 0 !important;
  right: 0 !important;
  position:absolute !important;
  padding:0 !important;

  & > * {
    display:none !important;
  }

  &:after {
    display: block;
    content: "\e014";
    position: absolute;
    top: 0;
    right: 0;
    text-indent: 0;
    font-family: 'Glyphicons Halflings';
    font-size: $font-size-h2;
    font-style: normal;
    font-weight: normal;
    padding: 15px;
  }
}
.ui-dialog-content {
  position: absolute !important;
  height: 90% !important;
  width: initial !important;
  left: 5% !important;
  top: 5% !important;
  right: 5% !important;
  bottom: 5% !important;
  background: #fff !important;
  border-radius: 0 !important;
  border: 1px solid $brand-primary !important;
  box-shadow: 0 5px 25px rgba(0, 0, 0, .38) !important;
  padding: $line-height-computed*.75 !important;

  h1 {
    margin: 0 0 $line-height-computed;
  }
}

.autodialog-content > #page-wrapper > .container-fluid {
  margin: 0;
  padding: 0;
}