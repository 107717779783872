// Styles for pages.
// -----------------------------------------------------------------------------

// We use specific page variant for anonymous users
// with user login form and password reset form
.not-logged-in {
  margin: 0 auto;
  max-width: 400px;
  min-width: 320px;

  .pane-page-logo {
    overflow: hidden;
  }

  .pane-user-login {
    clear: both;
  }

  // Hack to make 'pass' field look as normal form control
  .pane-user-login,
  .pane-password-recovery-widget {
    .form-actions {
      margin-top: $line-height-computed !important;
    }
  }
  .pane-password-recovery-widget {
    margin-top: 2 * $line-height-computed !important;
  }
}
