// Styles for basis tags
// -----------------------------------------------------------------------------
/* Link Styles */
a {
  color: $link-color;
  text-decoration: none;
  @include transition(color 200ms ease-in-out);

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: none;
  }
}
