//
// Panels
// --------------------------------------------------
// Panel contents
.panel-controls {
  border-color: $body-bg;
  margin-top: $line-height-computed !important;
  padding: 0;
}

.panel-heading,
.panel-footer {
  padding: 0;

  .panel > & {
    background: none;
  }
}

.panel-heading {
  padding-bottom: $line-height-computed/2;
}

.panel-body {
  @include clearfix;
  padding: 0;

  .panel-heading + & {
    margin-top: $line-height-computed/2;
  }

  + .panel-footer {
    margin-top: $line-height-computed/2;
  }

  > * {
    margin: 0;
  }

  > * + * {
    margin: $line-height-computed/2 0 0;
  }
}

.panel-footer {
  padding-top: $line-height-computed/2;
}

// Panel title within forms
.panel-title {
  form & {
    color: $brand-primary;
    font-size: $font-size-base;
  }
}

// Panel styles
.panel-info {
  @include panel-variant($brand-info, $panel-default-text, transparent, $panel-default-border);
}
