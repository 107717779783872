// Styles for overrides and fixes
// -----------------------------------------------------------------------------

// Fix font smooting of adminimal admin theme
body.adminimal-menu div#admin-menu a,
body.adminimal-menu div#admin-menu span {
  -webkit-font-smoothing: initial;
  letter-spacing: initial;
}

// Fix doubling of toggle-button in views
#modal-content > div .toggle-button {
  display:none;
}