// Typography
// -----------------------------------------------------------------------------
// Typography vars for Ecospas KP
$font-size-base:          14px;
$font-size-large:         ceil($font-size-base * 1.25); // ~18px
$font-size-small:         ceil($font-size-base * 0.85); // ~12px
$font-size-h1:            floor($font-size-base * 2.6); // ~36px
$font-size-h2:            floor($font-size-base * 1.8); // ~25px
$font-size-h3:            ceil($font-size-base * 1.6);  // ~23px
$font-size-h4:            ceil($font-size-base * 1.25); // ~18px
$font-size-h5:            $font-size-base;
$font-size-h6:            ceil($font-size-base * 0.85); // ~12px
