// Styles for Icons
// -----------------------------------------------------------------------------

.action {
  .glyphicon {
    color: $gray-light;
    -webkit-transition: color .25s ease;
       -moz-transition: color .25s ease;
        -ms-transition: color .25s ease;
         -o-transition: color .25s ease;
            transition: color .25s ease;

    &:hover {
      color: $gray;
    }
    &:active,
    &:focus {
      color: $gray-base;
    }
  }
}