// Styles for overlay menu
// -----------------------------------------------------------------------------
body.not-logged-in #overlay-menu-icon {
  display: none;
}

#overlay-menu-panel {
  padding: 0 !important;

  &,
  > .container-fluid,
  > .container-fluid > .row,
  > .container-fluid > .row > .radix-layouts-column1,
  > .container-fluid > .row > .radix-layouts-column2 {
    height: 100%;
  }

  > .container-fluid {
    padding: 0;

    > .row {
      margin: 0;

      > .radix-layouts-column1,
      > .radix-layouts-column2 {
        margin: 0;
        overflow: scroll;
        padding: 15px;
      }
    }
  }

  .panel-default:hover {
    border: 1px solid $gray-light;
  }
}

#mini-panel-overlay_menu_combined {
  > .container-fluid {
    padding: 0;
  }
}

#overlay-menu-icon {
  @include transition(all 250ms ease-in-out);
  background: $brand-primary;
  height: 44px;
  width: 131px;
  z-index: 100;

  img {
    display: none;
    opacity: 0;
  }

  &:after {
    color: $body-bg;
    content: '\e236';
    display: block;
    font-family: 'Glyphicons Halflings';
    font-size: $font-size-h2;
    font-style: normal;
    font-weight: normal;
    line-height: 35px;
    padding: 0;
    position: absolute;
    right: 10px;
    text-indent: 0;
    top: 5px;
  }

  &:before {
    @include transition(width 250ms ease-in-out);
    color: $body-bg;
    content: 'МЕНЮ';
    display: block;
    font-size: $font-size-h2;
    font-style: normal;
    font-weight: normal;
    line-height: 34px;
    padding: 0 5px 0 0;
    position: absolute;
    right: 35px;
    text-indent: 0;
    top: 5px;

    .scrolled & {
      display: none;
      width: 0;
    }
  }

  .scrolled & {
    width: 45px;
  }
}
